import {
  OPEN_API_CREATE_COMPLETION,
  OPEN_API_LOGBOOK_RECORD_FROM_TRANSCRIPT,
  OPEN_API_SUMMARIZE_DOC,
  OPEN_API_TRANSCRIBE,
  SUPABASE_EMBED_DOC
} from '../constants/api';
import * as twApi from './tw-api';

/**
 *
 * @param {string} orgId
 * @param {string} url
 *
 * @returns string // transcribed audio
 */
export const transcribeAudio = ({
  orgId,
  url,
  includeSummary = false,
  summaryCharLength = 250
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      orgId,
      url,
      ...(includeSummary ? { includeSummary, summaryCharLength } : {})
    };

    return twApi.post(OPEN_API_TRANSCRIBE, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * @summary Create Completion
 * @param {object} prompt // json
 *
 */
export const createCompletion = ({
  prompt
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      prompt
    };

    return twApi.post(OPEN_API_CREATE_COMPLETION, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * @summary creates JSON { hoursWorked, competencyIds } from transcript
 * @param {object} transcript
 * @param {object} apprenticeshipId
 *
 */
export const logbookRecordFromTranscript = ({
  transcript,
  apprenticeshipId
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      transcript,
      apprenticeshipId
    };

    return twApi.post(OPEN_API_LOGBOOK_RECORD_FROM_TRANSCRIPT, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * @summary creates embedding of a single file
 * @param {object} prompt // json
 * @note see: "summarizeDocTypes" for supported file types
 *
 */
export const embedDoc = ({
  refTable = '',
  orgId = '',
  id = '',
  url = ''
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      files: [{ [id]: url }],
      refTable,
      orgId
    };

    return twApi.post(SUPABASE_EMBED_DOC, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * @summary creates pdf from template & JSON
 * @param {object} prompt // json
 * @note see: "summarizeDocTypes" for supported file types
 *
 */
export const summarizeDoc = ({
  topic,
  rawText,
  url,
  orgId,
  outputCharLength,
  createPreSignedUrl
}) => {
  return new Promise((resolve, reject) => {
    const data = {
      ...(topic ? { topic } : {}),
      ...(url ? { url } : {}),
      ...(createPreSignedUrl ? { createPreSignedUrl } : {}),
      ...(rawText ? { rawText } : {}),
      organizationId: orgId,
      outputCharLength
    };

    return twApi.post(OPEN_API_SUMMARIZE_DOC, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const vectorSearchDocTypes = [
  'text/csv',
  // 'application/vnd.ms-excel', // EXCEL (.xls)
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // EXCEL (.xlsx)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // WORD (.docx)
  // 'application/msword', // WORD (.doc)
  'application/pdf'
];

export const summarizeDocTypes = [
  'text/csv',
  'application/json',
  'application/x-gzip', // .gz
  'application/vnd.ms-excel', // EXCEL (.xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // EXCEL (.xlsx)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // WORD (.docx)
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPT
  'application/vnd.ms-powerpoint', // PPT
  'application/msword', // WORD (.doc)
  'application/pdf'
];
